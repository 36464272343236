"use client";
import StrapiImage from "components/shared/Image";
import Markdown from "components/elements/Markdown";

import { FC, Fragment } from "react";
import {
	ComponentSectionsTrainingsAssociatedGame,
	Product,
	UploadFileEntity,
} from "__generated__/schema.graphql.types";
import { Separator } from "@/components/ui/separator";
import { Button } from "components/ui/button";
import Link from "next/link";
import { cn } from "@/lib/utils";
import styles from "styles/MaterialIcons.module.scss";

interface AssociatedGameProps {
	data: ComponentSectionsTrainingsAssociatedGame;
	pageAccentColor?: string;
	associatedProduct: Product;
}

type FocusTopicProps = {
	icon?: string;
	heading?: string;
	description?: string;
};

const FocusTopic = ({ icon, heading, description }: FocusTopicProps) => {
	return (
		<div className="grid grid-cols-[auto_auto] justify-items-start items-start gap-y-5 gap-x-4">
			<div className="bg-green-100 size-14 flex justify-center items-center rounded-xl">
				<span
					className={cn(
						styles.materialIcons,
						`col-span-1 row-span-2 text-green-600`,
					)}
					style={{
						// @ts-expect-error variables are unknown at compile time
						"--size": "1.25rem",
					}}
				/>
				<style jsx>{`
span {
position: relative;
}
span:after {
content: '${icon}';
position: absolute;
display: flex;
justify-content: center;
align-items: center;
top: 0;
left: 0;
right: 0;
bottom: 0;
}
span:before {
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
opacity: 0.15;
border-radius: 0.75rem;
}
`}</style>
			</div>
			<div className="flex flex-col text-left gap-y-1">
				<span className="font-semibold text-green-900 text-[0.875rem] leading-[1]">
					{heading}
				</span>
				<span className="font-normal text-green-900 text-[0.875rem] leading-[2] max-w-80">
					{description}
				</span>
			</div>
		</div>
	);
};

const pictureShadow = {
	boxShadow:
		"0px 100px 80px rgba(22, 101, 52, 0.07), 0px 41.7776px 33.4221px rgba(22, 101, 52, 0.0503198), 0px 22.3363px 17.869px rgba(22, 101, 52, 0.0417275), 0px 12.5216px 10.0172px rgba(22, 101, 52, 0.035), 0px 6.6501px 5.32008px rgba(22, 101, 52, 0.0282725), 0px 2.76726px 2.21381px rgba(22, 101, 52, 0.0196802)",
};

type PictureProps = {
	image?: UploadFileEntity;
	className?: string;
};

const Picture = ({ image, className }: PictureProps) => {
	return (
		<div
			className={cn(
				"overflow-hidden rounded-xl  bg-slate-100  left-0 right-0 top-0 bottom-0 aspect-square absolute border-[0.75rem] border-white transition-transform transform-gpu will-change-transform",
				className,
			)}
			style={pictureShadow}
		>
			<div className="relative w-full h-full">
				{image ? (
					<StrapiImage fill style={{ objectFit: "cover" }} strapi={image} />
				) : null}
			</div>
		</div>
	);
};

export const TrainingsAssociatedGame: FC<AssociatedGameProps> = ({ data }) => {
	const { kicker, product, body, images, focus_topics } = data;

	return (
		<div className="bg-green-50 selection:bg-green-900">
			<section
				className={cn(
					"container grid gap-x-8 gap-y-12 py-32 pt-24",
					"grid-cols-auto grid-flow-row",
					"lg:grid-flow-col lg:grid-cols-[1fr_1fr]",
				)}
			>
				<aside className="relative grid grid-cols-1 h-96 lg:h-full">
					<Picture
						image={images.data?.[0]}
						className={cn(
							"h-4/5 mr-auto mb-auto -rotate-6 hover:rotate-0 hover:scale-105",
							"lg:w-3/5 lg:h-auto lg:mx-auto lg:mb-auto",
						)}
					/>
					<Picture
						image={images.data?.[1]}
						className={cn(
							"h-4/5 ml-auto mt-auto rotate-6 hover:rotate-0 hover:scale-105",
							"lg:w-3/5 lg:h-auto lg:mx-auto lg:mt-auto",
						)}
					/>
					<Picture
						image={images.data?.[2]}
						className={cn(
							"h-full mx-auto my-auto hover:rotate-6 hover:scale-105",
							"lg:w-4/5 lg:h-auto lg:mx-auto lg:my-auto",
						)}
					/>
				</aside>
				<div className="flex flex-col gap-y-8">
					<div className="flex flex-col gap-y-4">
						<div>
							<span className="font-semibold uppercase text-[0.875rem] text-green-700">
								{kicker}
							</span>
							<h2 className="text-[2.25rem] my-0 text-green-900 leading-[1.5] font-semibold font-serif">
								{product?.data?.attributes?.name}
							</h2>
						</div>
						<Markdown
							content={body ?? ""}
							className="text-green-900 max-w-lg leading-7 text-[1rem] space-y-2"
						/>
						<div className="mt-6">
							<h4 className="text-[1rem] text-green-900 leading-6 font-semibold">
								Mängu põhiteemad
							</h4>
							<p className="text-[1rem] text-green-900 leading-7 font-normal">
								Just need teemad on selles mängus peamised:
							</p>
						</div>
						<div className="flex flex-col items-start  mt-3 lg:mt-6 gap-y-6">
							{focus_topics?.map((topic) => (
								<FocusTopic
									key={topic?.id}
									icon={topic?.icon ?? ""}
									heading={topic?.title ?? ""}
									description={topic?.description ?? ""}
								/>
							))}
						</div>
						<div className="mt-5">
							<Button
								asChild
								className="bg-green-500 text-white hover:bg-green-600"
							>
								<Link
									href={
										product?.data?.attributes?.page?.data?.attributes?.slug ??
										""
									}
								>
									Loe mängust lähemalt
								</Link>
							</Button>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
